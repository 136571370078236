//var request = require('superagent');

var Membership = function () {
    "use strict";
   
    // helpers
    var isNumeric = function (val) {
        //var float = parseFloat(val);
        //console.log('float.toString().length:' + float.toString().length);
        //console.log('val.length:' + val.length);
        //return float.toString().length === val.length;
        var reg = /^\d+$/;
        return reg.test(val);
    }

    var fallbackValidation = function (val) {
        return (val.length === 0 || (isNumeric(val) && val.length === 9));
    };

    var valid = function(value) {
        return fallbackValidation(value);
    };

    var validTrumf = function (value) {
        return isNumeric(value);
    };

    //var valid2 = function(value, callback) {
    //    request
    //        .get('http://local-fjordkraft.reaktorutv.no/Templates/Fjordkraft/WebServices/Membership/Membership.asmx/ValidateSasMembershipGet')
    //        .set('Content-Type', 'application/json')
    //        .query({ value: value })
    //        .end(callback);
    //};

    var callback = function (value, element) {
        return valid(value, function (err, res) {
            if (err) {
                console.log('ERROR');
                return false;
            } else {
                try {
                    var isValid = JSON.parse(res.body.d).IsValidNumber;
                    console.log('TRY');
                    return isValid ? isValid : false;
                } catch (ex) {
                    console.log('CATCH');
                    return false;
                }
            }
        });
    };

    var error = "Angi et gyldig medlemsnummer";



    // immediately execute the following with jquery dependency
    (function (jq) {
        if (jq) {
            // apply membership-sas
            jq.validator.addMethod('membership-sas', valid, error);            
            jq.validator.addMethod('membership-trumf', validTrumf, error);            
        }
    })(global.jQuery = global.jQuery || undefined);

    return {
        valid: valid,
        validTrumf: validTrumf,
        error: error
    };
};

module.exports = Membership;