// <reference path="FjkScripts.js" />
var TabFlip = function () {
    "use strict";
  

    //Toggle private/company customertype in footer
    $(function () {
        $('div.footer_customertype span a').click(function () {

            if ($(this).hasClass('active')) {
                return;
            }

            $('div.footer_customertype span a').removeClass('active');
            $(this).addClass('active');
            
            $('#footer_menu_private').toggle();
            $('#footer_menu_company').toggle();

            $('div.footer_so-me-bedrift').toggleClass('active');            
            $('div.footer_so-me-privat').toggleClass('active');

            if ($('div.footer-private-content').hasClass('active')) {
                $('div.footer-private-content').removeClass('active');
                $('div.footer-company-content').addClass('active');

                return;
            }
            $('div.footer-company-content').removeClass('active');
            $('div.footer-private-content').addClass('active');
            
        });
    });

    // Smartphone - Toggle private/company heading in footer
    $(function () {
        $('.footer--header.is-visible_smartphone').click(function () {

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }

            $(this).next('ul.footer--items').slideToggle('normal');
        });
    });


    $(function () {
        $('.slideToggleShow').click(function() {
            $('.slideToggleShowHide').slideToggle('normal');
        });
    });

    //Toggle showmore link/button
    $(function () {
        $('#ShowMoreButton').click(function () {
            $('#ShowMoreContainer').hide();
            $('.js-showMore').show('normal');
        });
    });


    //Toogle topmenu
    $(function () {
        $('.menu-icon, .menu-label, .menu-label__open').click(function () {
            $("#js-menu").slideToggle('normal');
        });
        $('.js-toggle-menu-group').click(function () {
            $('.js-menu-group').toggle();
        });
    });

};

module.exports = TabFlip;
