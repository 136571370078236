var Guid = require('./guid');

window.closePpModal = window.closePpModal || function (el) {
  var modal = el.closest('.modal-overlay');
  if (modal) {
    modal.classList.add('is-hidden');
  }
}
function closeModalOnOverlayClick(e) {
  if (e.target === this || e.target.classList.contains('modal-container')) {
    this.classList.add('is-hidden');
    this.removeEventListener('click', closeModalOnOverlayClick);
  }
}
window.openPpModal = window.openPpModal || function (el, selector) {
  var overlay = el.closest(selector).querySelector('.modal-overlay');
  overlay.addEventListener('click', closeModalOnOverlayClick);
  overlay.classList.remove('is-hidden');
}
window.openRestrictedPpModal = window.openRestrictedPpModal || function (el,selector) {
  var overlay = el.closest(selector).querySelector('.modal-overlay');
  overlay.classList.remove('is-hidden');
}

var Modal = function (options) {
  "use strict";
  var guid = Guid();
  var dismissableGuid = 'js-modal-dismissable-' + guid;
  var dismissableClass = 'prepended-dismiss';

  // private
  var m = {
    modalWrapperDisabled: options.modalWrapper === null,
    guid: guid,
    options: options,
    activeClass: '.js-modal-active',
    inactiveClass: options.inActiveClass === undefined ? '.js-modal-inactive' : options.inActiveClass,
    shadow: {
      id: 'modal-shadow-id',
      className: 'modal-shadow',
      instance: null
    },
    dismissable: {
      id: dismissableGuid,
      dismissableClass: dismissableClass,
      template: '' +
        '<div class="row end-xs ' + dismissableClass + '" id="' + dismissableGuid + '">' +
        '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">' +
        '<button id="js-overlay-close" type="button" class="button__clean icon-close-small gray" value="" />' +
        '</div>' +
        '</div>'
    },
    log: function (str) {
      if (m.options.debug) {
        console.log(str);
      }
    },

    onOpen: function () {
      var active = jQuery(m.options.modalWrapper + ' ' + m.activeClass),
        inactive = jQuery(m.options.modalWrapper + ' ' + m.inactiveClass);

      if (m.options.enableOverlay) {

        inactive.attr('style', 'display: block; z-index: 1003;');
        inactive.css('opacity', '0').animate({ 'opacity': '1' }, m.options.speed);
        m.addShadow(m.options.isTrondelagKraft).fadeIn(m.options.speed).click(m.close);

        // Display parent container on TrøndelagKraft
        var parent = $(m.inactiveClass).parent('.modal-inactive__container');
        if (parent !== undefined && parent !== null) {
          parent.css("display", "block").css("z-index", 1000);
        }

      } else {
        active.fadeOut(m.options.speed, function () {
          inactive.fadeIn(m.options.speed, function () {
            inactive.attr('style', 'display: block; opacity: 1;z-index: 1003;');
          });
        });
      }
    },

    onClose: function () {
      var active = jQuery(options.modalWrapper + ' ' + m.activeClass),
        inactive = jQuery(options.modalWrapper + ' ' + m.inactiveClass);

      if (m.options.enableOverlay) {
        m.removeShadow();
        inactive.fadeOut(m.options.speed, function () {
          inactive.attr('style', 'display: none;');

          // Hide parent container on TrøndelagKraft
          var parent = $(m.inactiveClass).parent('.modal-inactive__container');
          if (parent !== undefined && parent !== null) {
            parent.css("display", "none");
          }
        });
      } else {
        inactive.fadeOut(m.options.speed, function () {
          jQuery(this).attr('style', 'display: none;');
          active.fadeIn(m.options.speed, function () {
          });
        });
      }
    },

    init: function () {
      // bind click events
      jQuery(m.options.open).on('keydown click', function(event) {
        if (event.type === 'click' || event.keyCode === 13) {
          m.open();
        }
      });
      jQuery(m.options.close).on('keydown click', function(event) {
        if (event.type === 'click' || event.keyCode === 13) {
          m.close();
        }
      });

      if (m.options.enableOverlay && m.options.dismissable) {
        m.addDismissable();
      }

      var inactive = jQuery(m.options.modalWrapper + ' ' + m.inactiveClass);

      if (m.options.enableOverlay) {
        inactive.addClass('modal-inactive card');
      }
      inactive.attr('style', 'display: none; opacity: 0;z-index: 1003; position: relative; background: rgba(0,0,0, 1.0)');
      if (m.options.initOpen === true) {
        m.open();
      }

      inactive.find('.js-modal-pad-container').addClass('pad-left-double pad-right-double pad-bottom-double');
      if (m.options.dismissable === false) {
        inactive.find('.js-modal-pad-container').addClass('pad-top-sm');
      }

    },

    // open modal
    open: function () {
      m.onOpen();
      m.log('modal open');
    },

    // close modal
    close: function () {
      m.onClose();
      m.log('modal close');
    },

    // removes the modal markup
    remove: function () {
      m.log('modal remove');
    },

    addShadow: function (isTrondelagKraft) {

      var style = 'display: none; opacity: 0.5; zindex: 1002;';
      if (isTrondelagKraft) {
        style = 'display: none; opacity: 0.5;';
      }

      // setup shadow
      var shadow = jQuery('<div/>')
        .attr('style', style)
        .attr('id', m.shadow.id)
        .addClass(m.shadow.className);
      m.shadow.instance = shadow;
      jQuery('body').append(shadow);
      return jQuery('#' + m.shadow.id);
    },

    removeShadow: function () {
      // remove shadow
      jQuery('#' + m.shadow.id).fadeOut(m.options.speed, function () {
        $(this).remove();
      });
    },

    getShadow: function () {
      return jQuery('#' + m.shadow.id);
    },

    addDismissable: function () {
      //if ($('#js-overlay-close').length == 0) {
      var inactive = jQuery(m.options.modalWrapper + ' ' + m.inactiveClass);
      if (inactive.children("." + m.dismissable.dismissableClass).length) {
        return;
      }
      var template = jQuery(m.dismissable.template);
      template.click(m.close);
      inactive.prepend(template);
      //}

    }
  };

  // public
  return {
    init: m.init,
    remove: m.remove,
    open: m.open,
    close: m.close
  };
};

module.exports = Modal;
