var Guid = require('./guid');
var _ = require('lodash');

var MapOfNorway = function () {
  "use strict";

  var m = {
    elemSelector: null,

    loadMap: function () {
      m.guid = Guid();
      return m;
    },

    appendDOM: function (elemSelector) {
      // set DOM elem selector
      m.elemSelector = elemSelector;
      m.resetMap();
    },

    activateMap: function (countyId) {

      var countyName = m.getCountyNameById(countyId);

      m.resetMap();

      // remove active class on all counties
      $.each($(m.elemSelector + " .fylke"), function (index, elem) {
        m.removeClass($(elem), "active");
      });

      var activeCounty = $(m.elemSelector + " .fylke--" + countyName);

      m.addClass(activeCounty, "active");
      m.removeClass($(m.elemSelector), "untouched");

      activeCounty.detach().appendTo($(m.elemSelector));
    },

    resetMap: function () {

      m.addClass($(m.elemSelector), "norge");
      m.addClass($(m.elemSelector), "untouched");
      m.removeClass($(m.elemSelector), "nord");
      m.removeClass($(m.elemSelector), "sor");

      m.removeClass($(m.elemSelector).parent(), "nord");
      m.removeClass($(m.elemSelector).parent(), "sor");
    },

    addClass: function (elem, classToAdd) {
      var currClasses = "".concat(elem.attr("class")).replace(classToAdd, "");
      elem.attr("class", currClasses + " " + classToAdd + " ");
    },

    removeClass: function (elem, classToRemove) {
      var newClassList = elem.attr("class")
        .replace(classToRemove, "")
        .replace("undefined", "")
        .replace(classToRemove, "");

      elem.attr("class", newClassList);
    },

    /**
     * user-triggered function. Triggers map activation and changes map to correct region and county
     * @param int countyId
     * @param int priceAreaId
     * @param bool isTrondelagKraft
     * @returns void
     */
    changeToCounty: function (countyId, priceAreaId, isTrondelagKraft) {

      isTrondelagKraft = isTrondelagKraft || false;

      // set county
      m.activateMap(countyId);

      // set area
      var region = priceAreaId === 4 ? 'nord' : 'sor';

      // zoom to updated region
      m.removeClass($(m.elemSelector), "sor");
      m.removeClass($(m.elemSelector), "nord");
      m.addClass($(m.elemSelector), region);
      m.addClass($(m.elemSelector).parent(), region);

      // Set Nord-Trøndelag to active manually
      if (isTrondelagKraft) {
        var activeCounty = $(m.elemSelector + " .fylke--" + this.getCountyNameById(17));
        m.addClass(activeCounty, "active");
        m.removeClass($(m.elemSelector), "untouched");
      }
    },

    getCountyNameById: function (countyId) {
      var counties = [{
        id: 1,
        name: 'ostfold'
      },
      {
        id: 2,
        name: 'akershus'
      },
      {
        id: 3,
        name: 'oslo'
      },
      {
        id: 4,
        name: 'hedmark'
      },
      {
        id: 5,
        name: 'oppland'
      },
      {
        id: 6,
        name: 'buskerud'
      },
      {
        id: 7,
        name: 'vestfold'
      },
      {
        id: 8,
        name: 'telemark'
      },
      {
        id: 9,
        name: 'aust-agder'
      },
      {
        id: 10,
        name: 'vest-agder'
      },
      {
        id: 11,
        name: 'rogaland'
      },
      {
        id: 12,
        name: 'hordaland'
      },
      {
        id: 14,
        name: 'sogn-og-fjordane'
      },
      {
        id: 15,
        name: 'more-og-romsdal'
      },
      {
        id: 16,
        name: 'sor-trondelag'
      },
      {
        id: 17,
        name: 'nord-trondelag'
      },
      {
        id: 18,
        name: 'nordland'
      },
      {
        id: 19,
        name: 'troms'
      },
      {
        id: 20,
        name: 'finnmark'
      }
      ];

      var result = _.find(counties, function (c) {
        return c.id === countyId;
      });

      if (!result) {
        return '';
      }

      return result.name;
    }
  }; // end main object

  return {
    loadMap: m.loadMap,
    getCountyNameById: m.getCountyNameById,
    changeToCounty: m.changeToCounty,
    appendDOM: m.appendDOM,
    resetMap: m.resetMap
  };

};

module.exports = MapOfNorway;
