var Gradient = function() {
    "use strict";

    function getStyle(c1, c2, p1, offset) {

        if (offset) {

            if (p1 < 50) {
                p1 = p1 + offset;
            }

            if (p1 >= 50) {
                p1 = p1 - offset;
            }

        }

        var p2 = parseInt(p1) + 1;


        var style = '' +
        'background: ' + c1 + ';' +
        'background: -moz-linear-gradient(left, ' + c1 + ' 0%, ' + c1 + ' ' + p1 + '%, ' + c2 + ' ' + p2 + '%, ' + c2 + ' 100%);' +
        'background: -webkit-gradient(left top, right top, color-stop(0%, ' + c1 + '), color-stop(' + p1 + '%, ' + c1 + '), color-stop(' + p2 + '%, ' + c2 + '), color-stop(100%, ' + c2 + '));' +
        'background: -webkit-linear-gradient(left, ' + c1 + ' 0%, ' + c1 + ' ' + p1 + '%, ' + c2 + ' ' + p2 + '%, ' + c2 + ' 100%);' +
        'background: -o-linear-gradient(left, ' + c1 + ' 0%, ' + c1 + ' ' + p1 + '%, ' + c2 + ' ' + p2 + '%, ' + c2 + ' 100%);' +
        'background: -ms-linear-gradient(left, ' + c1 + ' 0%, ' + c1 + ' ' + p1 + '%, ' + c2 + ' ' + p2 + '%, ' + c2 + ' 100%);' +
        'background: linear-gradient(to right, ' + c1 + ' 0%, ' + c1 + ' ' + p1 + '%, ' + c2 + ' ' + p2 + '%, ' + c2 + ' 100%);' +
        "filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='" + c1 + "', endColorstr='" + c2 + "', GradientType=1 );";

        return style;
    }

    return {
        getStyle : getStyle
    };
};

module.exports = Gradient;
