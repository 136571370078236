var WindowResize = function() {
    "use strict";

    // always run this
    var always = function() {
    };

    var header = function() {
        $(window).resize(function () {
            always();
        });
    };

    // always run callbacks
    var onWindowResize = function (callback) {
        $(window).resize(function () {
            callback();
        });
    };

    var init = function () {
        $(function () {
            always();
            header();
        });
    };

    init();

    return {
        onWindowResize: onWindowResize
    };
};

module.exports = WindowResize;
