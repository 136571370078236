"use strict";
var FormButtons = function () {
  var formButtonSetup = function (jq) {
    var emulateInputSubmit = "input.attach-form[type=button]";

    jq(function () {
      // turn off autocomplete
      (function () {
        jq("form, form input").attr("autocomplete", "off");
      })();

      // <input type="number" maxlength="4" />
      jq("input[type=number]").keyup(function () {
        var inputNumber = jq(this);
        var max = parseInt(inputNumber.attr("maxlength"));

        if (max) {
          var removeLeastSignificant = inputNumber.val().substr(0, max);
          inputNumber.val(removeLeastSignificant).trigger("change");
        }
      });

      jq("form").bind("keypress", function (e) {
        if (e.keyCode === 13) {
          var form = jq(this);
          var attr = form.attr("datatosubmit");
          var doSubmit = form.hasClass("js-no-submit") === false;

          if (attr && doSubmit) {
            console.log("enter");
            jq("#" + attr).click();
          }
        }
      });

      // each xform => fix the xform datatosubmit
      jq.each(jq(".form__xform"), function (index) {
        // <form id="xformId<#2a>" datatosubmit="xformtosubmit<#1a>">
        // ...
        // <input form="xformId<#2b>" id="xformtosubmit<#1b>" type="button" >

        // #1a + #2a
        var xform = jq(this);
        var newDatatosubmit = xform.attr("datatosubmit") + index.toString();
        var newFormId = xform.attr("id") + index.toString();

        xform.attr("datatosubmit", newDatatosubmit).attr("id", newFormId);

        // #1b + #2b
        xform
          .find("input#xformtosubmit")
          .attr("id", newDatatosubmit)
          .attr("form", newFormId);
      });

      // take control of submit clicks due to validation
      jq(emulateInputSubmit).click(function () {
        var self = this;
        var currentButton = jq(self);
        var shouldValidate = currentButton.prop("formNoValidate") === false;

        var buttonFormReference = "#" + currentButton.attr("form");
        var validationGroup = currentButton.attr("data-validation");

        var form = jq("form" + buttonFormReference);

        // insert hidden field
        var count = form.find("input[name=" + currentButton.attr("name") + "]")
          .length;

        // we should have a button AND a hidden field
        if (count < 2) {
          var inputHidden = jq("<input>")
            .attr("type", "hidden")
            .attr("name", currentButton.attr("name"))
            .val(currentButton.attr("name"));

          form.append(inputHidden);
        }

        var validator;

        if (shouldValidate) {
          validator = jq(form).validate({
            //debug: true,
            submitHandler: function (f) {
              currentButton.attr('disabled', true); // disable button for 1 second to prevent double postings;
              setTimeout(function () {
                currentButton.attr('disabled', false);
              }, 1000);
              //form submition after form has validated
              f.submit();

              if (FKRW && FKRW.stepLoader) {
                FKRW.stepLoader.begin();
              }
            },
            invalidHandler: function () {},
            focusInvalid: false,
          });
        } else {
          validator = jq(form).validate();
          validator.destroy();
        }

        // if form should validate
        if (shouldValidate && validationGroup) {
          // special check for validation group
          var groupElements = jq(".validation-" + validationGroup);

          var list = [];
          for (var i = 0; i < groupElements.length; i++) {
            list.push(validator.element(groupElements[i]));
          }

          var isGroupValid = list.every(function (elem) {
            return elem;
          });

          if (isGroupValid) {
            validator.destroy(); // we destroy the plugin here, we have already
            currentButton.attr('disabled', true); // disable button for 1 second to prevent double postings;
            setTimeout(function () {
              currentButton.attr('disabled', false);
            }, 1000);
            form.submit(); // form.submit() refreshes site and reloads current form
          }
        } else {
          currentButton.attr('disabled', true); // disable button for 1 second to prevent double postings;
          setTimeout(function () {
            currentButton.attr('disabled', false);
          }, 1000);
          form.submit();
        }
      });
    });
  };

  formButtonSetup(jQuery); /* jQuery */

  return formButtonSetup;
};

module.exports = FormButtons;
