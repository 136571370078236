var Slider = require('./slider.js');
var Paths = require('./paths')();

/*
    usage:
    FKRW.ProductSelector(options).init();
*/

var ProductSelector = function (options) {
    "use strict";

    var o = {
        people: options.people,
        peoplecompany: options.peoplecompany,
        square: options.square,
        squarecompany: options.squarecompany,
        risk: options.risk,
        onChanged: options.onChanged,
        isTrondelagKraft: options.isTrondelagKraft
    };

    // get slider values
    // triggered on all slider change
    //var onChanged = function() {
    //    if(o.onChanged) {
    //        o.onChanged.call(undefined, getValues());
    //    }
    //};

    var ps = {
        people: {
            sliderOptions: {
                element: o.people,
                slider: {
                    onChanged: function () {
                        if (o.onChanged) {
                            o.onChanged.call(undefined, ps.getValues());
                        }
                    },
                    minClass: 'icon-family-few',
                    maxClass: 'icon-family-many',
                    displayValue: true,
                    isTrondelagKraft: o.isTrondelagKraft
                }
            },
            slider: null
        },

        peoplecompany: {
          sliderOptions: {
              element: o.peoplecompany,
              slider: {
                  onChanged: function () {
                      if (o.onChanged) {
                          o.onChanged.call(undefined, ps.getValues());
                      }
                  },
                  minClass: Paths.Relative + '/images/svg/en_person.svg',
                  maxClass: Paths.Relative + '/images/svg/to_personer.svg',
                  minClassIsSvg: true,
                  maxClassIsSvg: true,
                  displayValue: true,
                  isTrondelagKraft: o.isTrondelagKraft
              }
          },
          slider: null
        },

        square: {
            sliderOptions: {
                element: o.square,
                slider: {
                    onChanged: function () {
                        if (o.onChanged) {
                            o.onChanged.call(undefined, ps.getValues());
                        }
                    },
                    minClass: 'icon-house-small',
                    maxClass: 'icon-house-big',
                    displayValue: true,
                    isTrondelagKraft: o.isTrondelagKraft
                }
            },
            slider: null
        },

        squarecompany: {
          sliderOptions: {
              element: o.squarecompany,
              slider: {
                  onChanged: function () {
                      if (o.onChanged) {
                          o.onChanged.call(undefined, ps.getValues());
                      }
                  },
                  minClass: Paths.Relative + '/images/svg/liten_bedrift.svg',
                  maxClass: Paths.Relative + '/images/svg/stor_bedrift.svg',
                  minClassIsSvg: true,
                  maxClassIsSvg: true,
                  displayValue: true,
                  isTrondelagKraft: o.isTrondelagKraft
              }
          },
          slider: null
      },

        risk: {
            sliderOptions: {
                element: o.risk,
                slider: {
                    onChanged: function () {
                        if (o.onChanged) {
                            o.onChanged.call(undefined, ps.getValues());
                        }
                    },
                    hideValueLabel: true,
                    isTrondelagKraft: o.isTrondelagKraft
                }
            },
            slider: null
        },

        init: function () {

            // init sliders
            ps.people.slider = Slider().init(ps.people.sliderOptions);
            ps.peoplecompany.slider = Slider().init(ps.peoplecompany.sliderOptions);
            ps.square.slider = Slider().init(ps.square.sliderOptions);
            ps.squarecompany.slider = Slider().init(ps.squarecompany.sliderOptions);
            ps.risk.slider = Slider().init(ps.risk.sliderOptions);

        },

        getValues: function () {
            return {
                people: parseInt(ps.people.slider.getValue()),
                peoplecompany: parseInt(ps.peoplecompany.slider.getValue()),
                square: parseFloat(ps.square.slider.getValue()),
                squarecompany: parseFloat(ps.squarecompany.slider.getValue()),
                risk: parseInt(ps.risk.slider.getValue())
            };
        }
    };

    return {
        init: ps.init,
        getValues: ps.getValues
    };
};

module.exports = ProductSelector;
