"use strict";

// our namespace
window.FKRW = window.FKRW || {};
window.FKRW.ValidateEnabled = true;

// external
var jqValidate = require("./src/js/vendor/jquery.validate/jquery.validate.js");
var jqValidateLoc = require("./src/js/vendor/jquery.validate/localization/messages_no.js");
var jqValidateAdd = require("./src/js/vendor/jquery.validate/additional-methods.js");
var jqValidateDate = require("./src/js/vendor/jquery.validate/dateNO.js");
var jqValidatePhone = require("./src/js/vendor/jquery.validate/phoneNO.js")();
var jqValidateMembership = require("./src/js/vendor/jquery.validate/membership.js")();
var jqValidateCheckbox = require("./src/js/vendor/jquery.validate/checkbox-required.js")();
var jqRcrumbs = require("./src/js/vendor/jquery.rcrumbs/jquery.rcrumbs.js");

// internal
FKRW.CookieHelper = require("./src/js/cookie-helper");
FKRW.Guid = require("./src/js/guid");
FKRW.Loadable = require("./src/js/loadable");
FKRW.LoadableButton = require("./src/js/loadable-button");
FKRW.Modal = require("./src/js/modal");
FKRW.FormDatepicker = require("./src/js/form-datepicker")().init();
FKRW.FormButtons = require("./src/js/form-buttons")();
FKRW.TabFlip = require("./src/js/tab-flip")();
FKRW.RCrumbs = require("./src/js/rcrumbs")();
FKRW.PopUps = require("./src/js/popups")();
FKRW.Slider = require("./src/js/slider");
FKRW.MapOfNorway = require("./src/js/map-of-norway");
FKRW.WindowResize = require("./src/js/window-resize")();
FKRW.ProductSelector = require("./src/js/product-selector");
FKRW.FullControl = require("./src/js/full-control");
FKRW.MapOrCodePriceSelector = require("./src/js/map-or-code-price-selector");


var smootScroll = require("./src/js/smooth-scroll")({
  speed: 250,
  highlightTarget: true
});

FKRW.setDirtyOnBlur = function(el, msg) {
  el.classList.add("dirty");
  console.log(el.checkValidity(), el.validity);
  if ((el.validity.patternMismatch || el.validity.valueMissing) && msg) {
    el.setCustomValidity(msg);
  } else {
    el.setCustomValidity("");
  }
};

FKRW.setAllFieldsDirty = function(el) {
  el
    .closest("form")
    .querySelectorAll("input")
    .forEach(function(e) {
      e.classList.add("dirty");
    });
};

$(function() {
  var checkboxes = document.body.querySelectorAll(".checkbox__label--novalue");
  for (var i = 0; i < checkboxes.length; i++) {
    checkboxes[i].setAttribute("tabindex", "0");
  }
  document.body.addEventListener("keydown", function(e) {
    if (
      e.which == 32 &&
      e.target.classList.contains("checkbox__label--novalue")
    ) {
      e.target.click();
    }
  });
  $.validator.addMethod(
    "pattern",
    function pattern(value, element, param) {
      return new RegExp(param).test(value);
    },
    "Invalid format."
  );
  console.log($.validator.rules)
}); 

console.log("index.js loaded");
