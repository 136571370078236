module.exports = function (options) {
    "use strict";

    var o = {
        speed: options.speed ? options.speed : 500,
        highlightTarget: options.highlightTarget ? options.highlightTarget === true : false
    };

    (function (jq) {
        if (!jq) {
            console.log('jQuery dependency not found');
            return false;
        }

        // on ready
        jq(function () {
            jq('a.animate-anchor').click(function () {
                // reset anchor targets
                jq('.anchor-target').removeClass('brandColor--animate');

                var attr = jq(this).attr('href');
                var performantHit = attr.substring(0, 1) === '#' && attr.length > 1;

                // should not animate
                if (performantHit === false) {
                    return false;
                }

                var onFinishedScrolling = function() {
                    console.log('finished scrolling');
                };

                // intended jshint ignore, external code
                if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) { // jshint ignore:line

                    var target = jq(this.hash);
                    target = target.length ? target : jq('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        jq('html,body').animate({
                            scrollTop: target.offset().top,
                            complete: onFinishedScrolling
                        }, o.speed);
 
                        if (o.highlightTarget && jq(target).hasClass('js-no-highlight') === false) {                            
                            jq(target).addClass('brandColor--animate');
                        }
                        
                        return false;
                    }
                }
            });
        });
    }(jQuery));
};