var Guid = require('./guid');

var LoadableButton = function (config) {
    "use strict";
    /*{
        id: '#js-loadable-button',  // required, id
        timeout: integer,           // optional, in millis 
        delay: integer,             // optional, in millis
        debug: bool,                // optional, debug with console output
        onStop: function () {}      // optional, stop callback
        disableOff: bool,           // optional, disable active button
        additionalCss: jq css {}    // optional, style as string
    }*/

    var guid = Guid();
    var instance = 'js-loadable-instance-' + guid;

    var l = {
        guid: guid,
        instance: instance,
        delay: config.delay,
        id: config.id,
        timeoutMs: config.timeout,
        debug: config.debug,
        onStop: config.onStop,
        disableOff: config.disableOff,
        additionalCss: config.additionalCss,

        template: function () {
            return '<div id="' + l.instance + '" class="progress is-hidden loadable-button-wrapper"><span class="indeterminate"></span></div>';
        },

        init: function () {
            var loader = $(l.template());
            $(config.id).after(loader);
            l.log('init');
        },

        load: function () {
            var marginBuffer = 2;

            l.currentMargin = parseInt($(l.id).css('margin-bottom'));

            if (l.currentMargin <= marginBuffer) {
                l.marginBuffer = l.currentMargin;
            }

            // set loader css
            $('#' + l.instance).show().css({
                'margin-bottom': l.currentMargin - marginBuffer + 'px',
                'max-width': $(l.id).css('width')
            });

            // insert user opted css
            if (l.additionalCss) {
                $('#' + l.instance).css(l.additionalCss);
            }
            

            $(l.id).css({ 'margin-bottom': 0 });

            if (!l.disableOff) {
                setTimeout(function() {
                    $(l.id).attr('disabled', 'disabled');
                }, 10);

            }
            
            l.log('load');

            if (l.timeoutMs > 0) {
                l.timeout = setTimeout(function () {
                    l.stop('timed out');
                }, l.timeoutMs);
            }
        },

        stop: function (reason) {
            setTimeout(function () {
                $('#' + instance).hide();

                $(l.id).removeAttr('disabled').css({ 'margin-bottom': l.currentMargin + 'px' });

                l.log('stop');

                clearTimeout(l.timeout);
            
                if (l.onStop) {
                    l.onStop.call(undefined, reason);
                }

            }, l.delay ? l.delay : 0);
        },
        
        log: function (str) {
            if(l.debug) {
                console.log(str);
            }
        }
    };

    l.init();

    return {
        load: l.load,
        stop: l.stop
    };
};

module.exports = LoadableButton;