var popUps = function () {
    "use strict";

    function openChatWindow(width, height, url) {
        var leftPosition, topPosition;
        width = width;
        height = height;
        leftPosition = (window.screen.width / 2) - (width / 2);
        topPosition = (window.screen.height / 2) - ((height / 2) + 50);

        window.open(url, "Chat", 'width=' + width + ',height='+ height +', top=' + topPosition + ' left=' + leftPosition + '');
    }

    return { openChatWindow: openChatWindow};
};

module.exports = popUps;