/*
    usage:

    var datepicker = new FKRW.FormDatepicker();
    datepicker.init();
*/

var Pikaday = require('./vendor/pikaday/pikaday');
var moment = require('./vendor/moment/moment.min');

var FormDatepicker = function () {
    "use strict";

    var minimumAge = 18;
    var minusMinimumAgeYears = moment().subtract(minimumAge, 'years');
    var minus100Years = moment().subtract(100, 'years');
    var plus5Years = moment().add(5, 'years');
    var minus10Days = moment().subtract(10, 'days');
    var plus5Days = moment().add(5, 'days');
    // var firstOfMonth = moment().add(4, 'days');

    var CONFIG_NAME = {
        DEFAULTS: "DEFAULTS",
        METERREADING: "METERREADING",
        BORNDATE: "BORNDATE",
        ACCOUNTSTARTDATE: "ACCOUNTSTARTDATE"
    };

    var config = {

        selectorClass: 'js-pikaday',

        defaults: {
            minDate: minus100Years.toDate(),
            maxDate: plus5Years.toDate(),
            format: 'DD.MM.YYYY',
            yearRange: [minus100Years.year(), plus5Years.year()],
            firstDay: 1
        },

        meterReading: {
            minDate: minus10Days.toDate(),
            maxDate: plus5Days,
            format: 'DD.MM.YYYY',
            yearRange: [minus10Days.year(), plus5Days.year()],
            firstDay: 1
        },

        bornDate: {
            defaultDate: minusMinimumAgeYears.toDate(),
            minDate: minus100Years.toDate(),
            maxDate: minusMinimumAgeYears.toDate(),
            format: 'DD.MM.YYYY',
            yearRange: [minus100Years.year(), minusMinimumAgeYears.year()],
            firstDay: 1
        },
        accountStartDate: {
            defaultDate: minusMinimumAgeYears.toDate(),
            //minDate: minus10Days.toDate(),
            maxDate: plus5Days.toDate(),
            format: 'DD.MM.YYYY',
            yearRange: [minus10Days.year(), plus5Days.year()],
            firstDay: 1
        },

        get: function (SELECTED_CONFIG, element, inputValue, onSelect) {
            var cfg;

            switch (SELECTED_CONFIG) {

                case CONFIG_NAME.BORNDATE:
                    cfg = config.bornDate;

                    if (inputValue) {
                        cfg.defaultDate = moment(inputValue, cfg.format).toDate();
                    }
                    break;

                case CONFIG_NAME.METERREADING:
                    cfg = config.meterReading;

                    if (inputValue) {
                        cfg.defaultDate = moment(inputValue, cfg.format).toDate();
                    }
                    break;

                case CONFIG_NAME.ACCOUNTSTARTDATE:
                    cfg = config.accountStartDate;

                    if (inputValue) {
                        cfg.accountStartDate = moment(inputValue, cfg.format).toDate();
                    }
                    break;

                default:
                    cfg = config.defaults;
                    if (inputValue) {
                        cfg.defaultDate = moment(inputValue, cfg.format).toDate();
                    }
                    break;

            }

            // For ALL
            cfg.setDefaultDate = inputValue ? true : false;
            cfg.field = element;
            cfg.onSelect = onSelect;

            return cfg;
        }

    };

    var isIos = function () {
        return (navigator.userAgent.match(/iPad|iPhone/g) ? true : false);
    };

    var setDate = function(id, date) {
        jQuery('#' + id).val(date);
    };

    var init = function() {
        var pikadays = [];

        $('.' + config.selectorClass).each(function () {


            // element selectors
            var jqElem = $(this);
            var elemId = jqElem.attr('id');
            var elem = document.getElementById(elemId);


            // if type=text not already set in html
            if (jqElem.attr('type') !== 'text') {
                jqElem.attr('type', 'text');
            }


            // get preset values
            var inputValue = jqElem.val();


            // get requested config
            var isMeterReading = jqElem.hasClass('js-pikaday-meterreading');
            var isBornDate = jqElem.hasClass('js-pikaday-borndate');
            var isAccountStartDate = jqElem.hasClass('js-pikaday-accountstartdate');
            var SELECTED_CONFIG = isMeterReading ? CONFIG_NAME.METERREADING : isBornDate ? CONFIG_NAME.BORNDATE : isAccountStartDate ? CONFIG_NAME.ACCOUNTSTARTDATE : CONFIG_NAME.DEFAULTS;

            var onSelect = function (date) {
                console.log('selected: ' + moment(date, 'DD.MM.YYYY').toString());
            };

            var cfg = config.get(SELECTED_CONFIG, elem, inputValue, onSelect);

            // init Pikaday
            pikadays.push(new Pikaday(cfg));
        });
    };

    return {
        isIos: isIos,
        init: init,
        setDate: setDate
    };

};

module.exports = FormDatepicker;
