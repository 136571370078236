var PhoneNO = function () {
    "use strict";

    /*
    TODO: KRS-98
    Ny regel for valdering av telefonnummer

    * Tillate 15 tegn
    * Ingen bokstaver og mellomrom 
    * Tegn som paranteser,plustegn m� godtas.
    * Firefox/Chrome � her er det mye rart som skjer. 
    * * Ingen maks grense trigges
    * * det vises 2 linjer der linje nr 2 har verdi -1
    */

    var error = "Angi et gyldig mobilnummer";
    
    var valid = function(value) {
        var min = value.length >= 8;

        var max = value.length <= 15;
        var containsOnlyNumbers = true;
        var containsNoLetters = true;
        
        var containsNoSpaces = true;
        
        if (new RegExp("([0-9])").test(value) == false) {
            containsOnlyNumbers = false;
        }

        if (new RegExp("([a-zA-Z])").test(value) == true) {
            containsNoLetters = false;
        }

        if (/\s/.test(value) == true) {
            containsNoSpaces = false;
        }

        //console.debug("Telefonnummer:" + value + "</br>Min verdi:" +  min + "</br>Maks verdi:" + max + "</br>Bare tall:" + containsOnlyNumbers + "<br>Ingen bokstaver:" + containsNoLetters + "<br>Ingen mellomrom:" + containsNoSpaces);
        if (min && max && containsOnlyNumbers && containsNoLetters && containsNoSpaces) {
            return true;
        }
       
        return false;

    };

    var apply = function(jq) {
        jq.validator.addMethod('phoneNO', function(value, element) {
            return valid(value);
        }, error);
    };

    // immediately execute the following with jquery dependency
    (function (jq) {
        if (jq) { apply(jq); }
    })(global.jQuery = global.jQuery || undefined);

    // return full object for test purposes
    return {
        valid: valid,
        error: error
    };
};

module.exports = PhoneNO;