var FullControl = function (initialMonths, selector) {
  function addOrSubtract(input, modifier) {
    var result = parseInt(input, 10) + modifier;
    if (result < 10) {
      return "0" + result;
    }
    return result + "";
  }
  var c = document.querySelector(selector);
  if (!c) {
    return;
  }
  var form = c.closest("form");
  var submit = c.parentElement.querySelector('input[type="submit"');
  var outOfMonthsText =
    c.dataset.nomoremonths || "Du har brukt begge dine betalingsfrie måneder";
  var adjacentText =
    c.dataset.adjacent || "Du kan ikke ha to betalingsfrie måneder på rad";
  var cantSelectInThePast =
    c.dataset.inthepast || "Denne måneden er i fortiden";
  var cantSelectTooFarInFuture =
    c.dataset.toofarinfuture ||
    "Kan ikke velge måneder mer enn ett år frem i tid.";
  var chosenMonths = (initialMonths || "").split(",").filter(function (m) {
    return !!m;
  });
  chosenMonths = chosenMonths.map(function (m) {
    m = m.trim();
    var arr = m.split("/");
    return arr[0] + addOrSubtract(arr[1], 0);
  });
  chosenMonths.sort();
  var originalChosenMonths = chosenMonths.join(",");
  var chosenMonthsPerYear;
  var today = new Date();
  var thisMonthString =
    today.getFullYear() + addOrSubtract(today.getMonth() + 1, 0);
  var nextYearString =
    today.getFullYear() + 1 + addOrSubtract(today.getMonth() + 1, 0);

  function setSelectable(month, months) {
    var monthString = getMonthString(month);
    if (thisMonthString >= monthString) {
      month.dataset.hovertext = cantSelectInThePast;
      month.classList.add("invalid");
      return;
    }
    var pos = months.indexOf(month);
    var from = pos - 12 < 0 ? 0 : pos - 12;
    var to = pos + 12 > months.length ? months.length : pos + 12;
    var numChosenInInterval = 0;
    for (var i = from; i < to; i++) {
      if (chosenMonths.indexOf(getMonthString(months[i])) >= 0) {
        numChosenInInterval++
      }
    }
    if (numChosenInInterval > 1) {
      month.dataset.hovertext = "Kan bare velge to måneder i en tolvmånedersperiode"
      month.classList.add("invalid")
      return
    }
    if (nextYearString < monthString) {
      month.dataset.hovertext = cantSelectTooFarInFuture;
      month.classList.add("invalid");
      return;
    }
    if (chosenMonthsPerYear[month.dataset.year].length >= 2) {
      month.dataset.hovertext = outOfMonthsText;
      month.classList.add("invalid");
      return;
    }
    var negateMonths;
    switch (month.dataset.month) {
      case "01":
        negateMonths = [
          addOrSubtract(month.dataset.year, -1) + "12",
          month.dataset.year + "02"
        ];
        break;
      case "12":
        negateMonths = [
          addOrSubtract(month.dataset.year, 1) + "01",
          month.dataset.year + "11"
        ];
        break;
      default:
        negateMonths = [
          month.dataset.year + addOrSubtract(month.dataset.month, -1),
          month.dataset.year + addOrSubtract(month.dataset.month, 1)
        ];
    }
    if (
      negateMonths.some(function (x) {
        return chosenMonths.indexOf(x) >= 0;
      })
    ) {
      month.dataset.hovertext = adjacentText;
      month.classList.add("invalid");
    } else {
      month.classList.remove("invalid");
    }
  }

  function update() {
    submit.disabled = false;
    var months = Array.prototype.slice.call(
      document.querySelectorAll(selector + " .month")
    );
    chosenMonthsPerYear = {};
    var thisYear = new Date().getFullYear();
    chosenMonthsPerYear[thisYear + ""] = chosenMonths.filter(function (x) {
      return (
        x.startsWith(thisYear + "") &&
        months.some(function (m) {
          return x === m.dataset.year + m.dataset.month;
        })
      );
    });
    var nextYear = thisYear + 1;
    chosenMonthsPerYear[nextYear + ""] = chosenMonths.filter(function (x) {
      return (
        x.startsWith(nextYear + "") &&
        months.some(function (m) {
          return x === m.dataset.year + m.dataset.month;
        })
      );
    });
    months.forEach(function (month) {
      var sel = month.dataset.year + month.dataset.month;
      if (
        chosenMonths.some(function (s) {
          return s === sel;
        })
      ) {
        month.classList.add("active");
        month.classList.remove("invalid");
        var monthString = month.dataset.year + month.dataset.month;
        if (thisMonthString >= monthString) {
          month.dataset.hovertext = cantSelectInThePast;
          month.classList.add("invalid");
        }
      } else {
        month.classList.remove("active");
        setSelectable(month, months);
      }
    });
    chosenMonths.sort();
    var monthsChosen = chosenMonthsPerYear[thisYear].length;
    var monthsLeft = document.querySelector("#MonthsLeft");
    if (monthsLeft) {
      monthsLeft.innerHTML = 2 - monthsChosen;
    }
    var monthsAmount = document.querySelector("#MonthsAmount");
    var amount;
    var accountInput = document.querySelector("#encAccountCategory");
    document.querySelector("#pfmOne").value = chosenMonths[0] || "";
    document.querySelector("#pfmTwo").value = chosenMonths[1] || "";
    accountInput.value = c.dataset.monthsaccount;
    if (chosenMonths.join(",") === originalChosenMonths) {
      amount = c.dataset.original;
      submit.disabled = true;
    } else if (monthsChosen === 0) {
      amount = c.dataset.nomonths;
      accountInput.value = c.dataset.nomonthsaccount;
    } else if (monthsChosen === 1) {
      amount = c.dataset.onemonth;
    } else if (monthsChosen === 2) {
      amount = c.dataset.twomonths;
    }
    monthsAmount.innerHTML = parseFloat(amount)
      .toFixed(2)
      .replace(".", ",");
  }
  update();
  c.addEventListener("click", function (e) {
    var month = e.target.parentElement;
    if (!month.classList.contains("month")) {
      return false;
    }
    if (
      month.classList.contains("active") &&
      !month.classList.contains("invalid")
    ) {
      chosenMonths = chosenMonths.filter(function (m) {
        return m !== month.dataset.year + month.dataset.month;
      });
    } else if (!month.classList.contains("invalid")) {
      chosenMonths.push(month.dataset.year + month.dataset.month);
    }
    update();
    [].forEach.call(form.querySelectorAll('input[name="pfm"]'), function (item) {
      form.removeChild(item);
    });

    chosenMonths
      .forEach(function (m) {
        var el = document.createElement("input");
        el.setAttribute("type", "hidden");
        el.setAttribute("name", "pfm");
        el.value = m;
        form.appendChild(el);
      });
    return false;
  });

  function getMonthString(month) {
    return month.dataset.year + month.dataset.month;
  }

  function isChosen(month) {
    return chosenMonths.indexOf(getMonthString(month)) >= 0;
  }
};

module.exports = FullControl;
