/**
 
 */


var ValidateCbLeastOne = function () {
    "use strict";

    var error = "Velg";


    var valid = function(value, element, jq) {
                
        var total = jq('.atleast-one-required:visible').length;
        var allVisibleCheckboxesCount = jq('.atleast-one-required:visible:checked').length;
        
        // update master
        if (allVisibleCheckboxesCount > 0 || total === 0) {
            jq('.atleast-one-required-master').val('true');
        } else {
            jq('.atleast-one-required-master').val('false');
        }

        // always return true
        return true;
    };

    var validMaster = function (value, element, jq) {

        if (jq('.atleast-one-required-master').val() === 'true') {
            return true;
        } else {
            return false;
        }

    };

    var bindCbChanges = function (jq) {        

        // bind changes on atleast-one-required checkboxes
        jq('.atleast-one-required').change(function () {

            jq('#atleastOneRequiredMaster-error').remove();
            valid(undefined, undefined, jq);

        });

        var customError = jq('#atleastOneRequiredMaster').data('error');
        error = customError ? customError : error;
    };

    var apply = function (jq) {

        bindCbChanges(jq);

        valid(undefined, undefined, jq);

        jq.validator.addMethod('atleast-one-required', function (value, element) {
            return valid(value, element, jq);
        }, error);

        jq.validator.addMethod('atleast-one-required-master', function (value, element) {
            return validMaster(value, element, jq);
        }, error);

    };


    // immediately execute the following with jquery dependency
    (function (jq) {
        if (jq) { apply(jq); }
    })(global.jQuery = global.jQuery || undefined);

    // return full object for test purposes
    return {
        valid: valid,
        error: error,
        bindCbChanges: bindCbChanges
    };
};

module.exports = ValidateCbLeastOne;