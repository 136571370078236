var Guid = require('./guid');
var Paths = require('./paths')();

/**
 * used in order forms
 */
var Loadable = function (config /*{loaderWrapper:string, debug:boolean, loaderText:string, brand:string}*/) {
  "use strict";
  var guid = Guid();
  var text = config.loaderText;
  var brand = config.brand;
  var isTrondelagKraft = config.isTrondelagKraft;
  var loaderImage = "/images/fjk-preloader.gif";

  if (brand === "TKAS") {
    loaderImage = "/images/tk/tk-venteanim.gif";
  }

  if (isTrondelagKraft === "true") {
    loaderImage = "/images/tktem/trondelagkraft-loader2.gif";
  }

  // private
  var l = {
    guid: guid,
    debug: config.debug ? true : false,
    loaderWrapper: config.loaderWrapper,
    loader: '.js-loadable-loader' + '.' + guid,

    template: '' +
      '<div class="loadable__item js-loadable-loader depth-positive-1 ' + guid + '">' +
      '<div class="row center-xs loadable__container">' +
      '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">' +
      '<img src="' + Paths.Relative + loaderImage + '" style="width: 100px;" />' +
      '</div>' +
      '<div class="progress loadable-button-wrapper"><span class="indeterminate"></span></div>' +
      '<div class="loadable__text">' + text + '</div>' +
      '</div>' +
      '</div>',
    timer: {
      begin: 0,
      end: 0,
      diff: function () {
        return this.end - this.begin;
      }
    },

    log: function (str) {
      if (l.debug) {
        console.log(str);
      }
    },

    // insert template into loadable wrapper
    init: function () {
      jQuery(l.loaderWrapper).prepend(l.template);
      jQuery(l.loader).hide();
      l.log("loader init");
    },

    // begin loading, shows loader
    begin: function () {
      l.timer.begin = new Date().getMilliseconds();
      jQuery(l.loader).show();
      jQuery(l.loaderWrapper).children().last().addClass('depth-normal');
      l.log("loader begin");
    },

    // loading ends, hides loader
    done: function () {
      l.timer.end = new Date().getMilliseconds();
      jQuery(l.loader).hide();
      jQuery(l.loaderWrapper).children().last().removeClass('depth-normal');
      l.log("loader end. " + l.timer.diff() + "ms");
    },

    // removes the loader template
    remove: function () {
      jQuery(l.loaderWrapper).remove(l.loader);
    }
  };

  // public
  return {
    init: l.init,
    begin: l.begin,
    done: l.done,
    remove: l.remove
  };
};

module.exports = Loadable;
