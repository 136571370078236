var axios = require("axios");
const { forEach } = require("lodash");

const formatDate = (date, short) => {
  function padzero(n) {
    return n < 10 ? "0" + n : n;
  }
  var parsedDate = new Date(Date.parse(date));
  var todaysDate = padzero(parsedDate.getDate());
  var todaysMonth = padzero((parsedDate.getMonth()+1));
  var year = parsedDate.getFullYear();
  var hours = padzero(parsedDate.getHours());
  var minutes = padzero(parsedDate.getMinutes());
  if (short) { return `${todaysDate}.${todaysMonth}.${year}.`; }
  else { return `${todaysDate}.${todaysMonth}.${year}, kl. ${hours}:${minutes}.`; }
}
function formatPrice(price, support = 0) {
  if (price === undefined || support === undefined) {
    return undefined;
  }
  return `${(parseFloat(price.toString().replace(",", ".")) - (parseFloat(support.toString().replace(",", ".")))).toFixed(2).toString().replace(".", ",")}`;
}

const replaceOrAddPostalCodeInUrl = (code, url) => {
  let codeStr = "postalCode=";
  //replace
  if (url.includes(codeStr)) {
    let subStr = url.substring(url.search(codeStr), url.length);
    let oldStr = subStr.split("&")[0];
    return url.replace(oldStr, codeStr + code);
  }
  //or add
  return url + (url.includes("?")?"&":"?") + codeStr + code;
}

var MapOrCodePriceSelector = function () {
  "use strict";

  const productComponents = Array.from(document.querySelectorAll(".product-selector-product-card"));
  const infoComponents = Array.from(document.querySelectorAll(".postalcode-prices-info"));
  const productBlockComponents = Array.from(document.querySelectorAll(".product-card"));

  var mapElectricitySupport = [];
  var mapPostalCodes = [];
  var areas = ["Nord-Norge", "Midt-Norge", "Øst-Norge", "Vest-Norge", "Sør-Norge"];
  var NOareas = ["NO4", "NO3", "NO1", "NO5", "NO2"];
  var addElectricitySupport = false;
  const priceAreaMap = new Map();

  function addMva(price, priceArea){
    if (price == null)
    {
        return 0;
    }
    else if (priceArea == "NO4"){
      return parseFloat(price.replace(",", "."));
    }
    return parseFloat(price.replace(",", ".")) * 1.25;
  }

  function getAverageSpotprices(){
    for (let i = 1; i <= 5; i++) {
      const key = `NO${i}`;
      const elements = {
        yesterday: addMva((document.getElementById(`${key}-price-yesterday`) ? document.getElementById(`${key}-price-yesterday`).innerHTML : "")),
        today: addMva((document.getElementById(`${key}-price-today`) ? document.getElementById(`${key}-price-today`).innerHTML : ""), key),
        tomorrow: addMva((document.getElementById(`${key}-price-tomorrow`) ? document.getElementById(`${key}-price-tomorrow`).innerHTML : ""), key)
      };
      
      priceAreaMap.set(key, elements);
    }
}

function organizePricesByDay(addElectricitySupport = false) {
  document.querySelectorAll(".map-price-selector__choice");
  
  priceAreaMap.forEach((prices, priceArea) => {
    const support = addElectricitySupport ? getSupportForPriceArea(priceArea) : undefined;

    ["yesterday", "today", "tomorrow"].forEach(day => {
      const price = formatPrice(prices[day], support);
      const id = `${priceArea}-price-${day}`;
      const priceString = price !== null && price !== undefined && price !== "NaN" ? `${price} øre/kWh` : (day === 'tomorrow' ? "Kommer 14:00" : "--");
      const element = document.getElementById(id);
      if (element) {
        element.innerHTML = priceString;
      }
    });
  });

  initMapButtons();
}



function toggleStromstotte(){
  var toggleSupportSwitch = document.getElementById("toggle-electricity-support");
  
  function toggleSupportSwitchHandler() {
    addElectricitySupport = !addElectricitySupport;
    organizePricesByDay(addElectricitySupport);
  }

  toggleSupportSwitch.addEventListener("click", toggleSupportSwitchHandler);
  toggleSupportSwitch.addEventListener("keydown", function(event) {
    if (event.keyCode === 13) {
      toggleSupportSwitchHandler();
    }
  });
}


  function updateProductsShown(area) {
    var areaTags = ["NO4", "NO3", "NO1", "NO5", "NO2"];

    productBlockComponents.forEach(c => { 
      // check if productBlockComponent should be shown by price-area
      var priceAreaClassList = Array.from(c.querySelector(".price-area").classList);
      var hasNoPriceAreaTags = !priceAreaClassList.some(x => areaTags.includes(x));
      
      if (area != null) { 
        var selectedAreaTag = areaTags[area];
        if (priceAreaClassList.includes(selectedAreaTag) || hasNoPriceAreaTags) { c.classList.remove("hide-product"); }
        else { c.classList.add("hide-product"); }
      } else {
        var hasNoPriceAreaTags = !priceAreaClassList.some(x => areaTags.includes(x));
        if (hasNoPriceAreaTags) { c.classList.remove("hide-product"); }
        else { c.classList.add("hide-product"); }
      }
    });
  }

  function initMapButtons() {
    var mapButtons = document.querySelectorAll(".map-price-selector__choice");
    mapButtons.forEach(function (button) {
      button.addEventListener('click', handleButtonClick);
      button.addEventListener('keydown', handleButtonKeyPress);
    });
  
    function handleButtonClick(event) {
      var selectedArea = this.id.split("-").pop();
      document.querySelectorAll(".mapPriceSelector_link").forEach(e => e.textContent = areas[selectedArea]);
      selectArea(selectedArea);
      updateMapPaths(selectedArea);
    }
  
    function handleButtonKeyPress(event) {
      if (event.keyCode === 13) {
        handleButtonClick.call(this, event);
      }
    }
  
    function updateMapPaths(selectedArea) {
      ["#nord-", "#midt-", "#ost-", "#vest-", "#sor-"].forEach((areaId, i) => {
        var paths = document.querySelectorAll(areaId + "path");
        var checkmarks = document.querySelectorAll(areaId + "checkmark");
        var checkmarksUnchecked = document.querySelectorAll(areaId + "checkmark-unchecked");
        if (i == selectedArea) {
          paths.forEach(e => e.style.opacity = "1");
          checkmarks.forEach(e => e.classList.remove("is-hidden"));
          checkmarksUnchecked.forEach(e => e.classList.add("is-hidden"));
        } else {
          paths.forEach(e => e.style.opacity = "0.2");
          checkmarks.forEach(e => e.classList.add("is-hidden"));
          checkmarksUnchecked.forEach(e => e.classList.remove("is-hidden"));
        }
      })
    }
  }
  

  function initSelectDayButtons() {
    var dates;
    var showDates = false;
    const dateYesterdayElement = document.getElementById("dateYesterday");
    if (dateYesterdayElement) {
      showDates = true;
    }
    if(showDates){
      // Note: All these dates are just when it was last updated now instead
      var dateYesterday = "";
      var dateToday = "";
      var dateTomorrow = "";
      if(!formatDate(document.getElementById("dateYesterday").innerHTML).startsWith("NaN")){
        dateYesterday = formatDate(document.getElementById("dateYesterday").innerHTML, true);
        dateToday = formatDate(document.getElementById("dateToday").innerHTML, true);
        dateTomorrow = formatDate(document.getElementById("dateTomorrow").innerHTML, true);
      }
      document.getElementById("price-last-updated-text").innerHTML = dateToday;
      dates = [dateYesterday, dateToday, dateTomorrow];
    }
    
    document.querySelectorAll(".day-price-selector-choice").forEach(e => {
      e.addEventListener('keydown', function(event) {
        if (event.keyCode === 13) {
          handleSelection(e);
        }
      });
      e.addEventListener('click', function() {
        handleSelection(e);
      });
    });
    
    function handleSelection(e) {
      var dayIndex = e.id.split("-").pop();
      if (showDates) {
        document.getElementById("price-last-updated-text").innerHTML = dates[dayIndex];
      }
      styleSelectedDay(dayIndex);
      showSelectedDaysTable(dayIndex);
    }
    
  }

  function showSelectedDaysTable(dayIndex){
    document.getElementById("day-selector-choice-0").classList.add("is-hidden");
    document.getElementById("day-selector-choice-1").classList.add("is-hidden");
    document.getElementById("day-selector-choice-2").classList.add("is-hidden");
    document.getElementById("day-selector-choice-"+ dayIndex).classList.remove("is-hidden");
  }

  function styleSelectedDay(dayIndex) {
    const dayIds = ['0', '1', '2'];
  
    dayIds.forEach((dayId) => {
      const dayElement = document.getElementById(`day-price-selector-choice-${dayId}`);
      dayElement.classList.remove('menubar-day-selected');
      dayElement.classList.add('menubar-day');
    });
  
    const selectedDayElement = document.getElementById(`day-price-selector-choice-${dayIndex}`);
    selectedDayElement.classList.add('menubar-day-selected');
    selectedDayElement.classList.remove('menubar-day');
  }
  

  function getElectricitySupport() {
    mapElectricitySupport[2] = document.getElementById("supportNO1").innerHTML;
    mapElectricitySupport[4] = document.getElementById("supportNO2").innerHTML;
    mapElectricitySupport[1] = document.getElementById("supportNO3").innerHTML;
    mapElectricitySupport[0] = document.getElementById("supportNO4").innerHTML;
    mapElectricitySupport[3] = document.getElementById("supportNO5").innerHTML;
    organizePricesByDay();
  }

  // HELPER for getting strømstøtte.. Pricearea handling should be cleaned up
  function getSupportForPriceArea(priceArea) {
    if(priceArea == "NO1"){
      return mapElectricitySupport[2];
    }
    else if(priceArea == "NO2"){
      return mapElectricitySupport[4];
    }
    else if(priceArea == "NO3"){
      return mapElectricitySupport[1];
    }
    else if(priceArea == "NO4"){
      return mapElectricitySupport[0];
    }
    else if(priceArea == "NO5"){
      return mapElectricitySupport[3];
    }
  }
  
  function selectArea(area) {
    if (area != null) {
      let price = null;
      let support = null;

      document.querySelectorAll(".product-card--infolink")
        .forEach(x => {
          x.href = replaceOrAddPostalCodeInUrl(mapPostalCodes[area], x.href);
        })
      price = priceAreaMap.get(NOareas[area]);
      support = mapElectricitySupport[area];
      document.querySelectorAll(".map-price-selector__selected-price").forEach(e => {
        e.innerText = formatPrice(price["today"]);
      });

      updateProductsShown(area)

      productComponents.forEach(c => {
        var pricetoday = formatPrice(price["today"]);
        if (pricetoday != null && pricetoday != undefined && pricetoday != "NaN") {
          // set prices for variable products, hidden on fixed products
          c.querySelector(".product-card-variable-price").textContent = formatPrice(price["today"]) + " øre/kWh";
          c.querySelector(".product-card-financial-support").textContent =  formatPrice(price["today"], support) + " øre/kWh";

          // move prices from producthub that currently populate "orig" elements.
          // Fixed prices are hidden on variable products, and markup prices are hidden on fixed products.
          [".product-card-fixed-price", ".product-card-markup-price", ".product-card-monthly-price"].forEach(str => {
            c.querySelector(str).innerText = formatPrice(parseFloat(c.querySelector(str + "-orig").innerText.replace(",", ".")) / (area == 0 ? 1.25 : 1)) + (str.includes("fixed") ? " øre/kWh" : "");
          });
          c.querySelector(".product-card-financial-support-fixed").textContent =  formatPrice(parseFloat(c.querySelector(".product-card-fixed-price").innerText.replace(",", ".")), support) + " øre/kWh";
        }
      });

      infoComponents.forEach(c => {
        if (area == 0) { c.classList.remove("is-hidden"); }
        else { c.classList.add("is-hidden"); }
      });
    }
    else {
      // remove prices and postalcodes from links if area doesn't exist
      productComponents.forEach(c => {
        [".product-card-variable-price", ".product-card-fixed-price"].forEach(str => {
          c.querySelector(str).innerText = "--";
        });
      });
      Array.from(document.querySelectorAll(".product-card--infolink"))
        .forEach(x => {
          x.href = replaceOrAddPostalCodeInUrl("", x.href);
          })
    }
  }

  return {
    getElectricitySupport: getElectricitySupport,
    getAverageSpotprices: getAverageSpotprices,
    selectArea: selectArea,
    initMapButtons: initMapButtons,
    updateProductsShown: updateProductsShown,
    initSelectDayButtons: initSelectDayButtons,
    organizePricesByDay: organizePricesByDay,
    toggleStromstotte: toggleStromstotte
  };
};

module.exports = MapOrCodePriceSelector;
