var Guid = require('./guid');
var Gradient = require('./bg-gradient')();
var Helper = require('./helper');

var Slider = function () {
  "use strict";

  var self = {
    options: null,
    guid: null,
    instance: null,
    instanceAttr: null,
    renderedTemplate: null,
    displayValueId: '',
    rowId: '',
    /**
     * Renders the styling wrapper with a new input element based on sanitized options.slider value object
     * @returns {string}
     */
    template: function () {

      var value = self.options.slider.value;
      return '' +
        (self.options.slider.isTrondelagKraft ? self.valueTemplate(value, self.getPercentByValue(value), !self.options.slider.displayValue) : '') +

        '<div class="slider">' +
        '<div id="' + self.rowId + '" class="slider-row" style="position: relative;">' +

        (self.options.slider.minClassIsSvg !== null && self.options.slider.minClassIsSvg === true
          ? '<span class="slider-col__icon svg-icon"><img src="' + self.options.slider.minClass + '" /></span>'
          : (self.options.slider.minClass ? '<span class="slider-col__icon ' + self.options.slider.minClass + '"></span>' : ''))
        +
        '<input ' +
        'type="range" ' +
        'class="slider-col__input ' + self.options.slider.classes + ' ' + self.guid + '  " ' +
        'id="' + self.options.slider.id + '" ' +
        'min="' + self.options.slider.min + '" ' +
        'max="' + self.options.slider.max + '" ' +
        'value="' + self.options.slider.value + '" ' +
        'step="' + self.options.slider.step + '" ' +
        //'shape="' + self.instance[0].dataset.shape + '" ' + // Can be set to 'exponential'. Defaults to 'linear'.
        '>' +
        (self.options.slider.maxClassIsSvg !== null && self.options.slider.maxClassIsSvg === true
          ? '<span class="slider-col__icon slider-col__icon--right svg-icon"><img src="' + self.options.slider.maxClass + '" /></span>'
          : (self.options.slider.maxClass ? '<span class="slider-col__icon slider-col__icon--right ' + self.options.slider.maxClass + '"></span>' : ''))
        +
        '</div>' +
        '</div>' +
        '' +

        (self.options.slider.displayValue && !self.options.slider.isTrondelagKraft ? self.valueTemplate(value, self.getPercentByValue(value)) : '') +

        '';
    },

    /**
     * Renders value on top of range knot
     * @returns {string}
     */
    valueTemplate: function (value, percentage, hideValueLabel) {

      var style = '';

      // if (self.options.slider.displayValuePercentage) {
      //     //style = 'left: ' + self.valueTemplatePercentage(percentage) + '%;';
      // }

      if (hideValueLabel) {
        return '';
      }
      // if(self.options.slider.isTrondelagKraft){
      //     style = 'display: none !important;';
      // }
      return '' +
        '<div id="' + self.displayValueId + '" ' +
        'class="slider-input-value" style="' + style + '">' +
        value +
        '</div>' +
        '</div>';
    },

    updateValueTemplate: function (value, percentage) {

      Math.easeIn = function (val, min, max, strength) {
        val /= max;
        return (max - 1) * Math.pow(val, strength) + min;
      };

      // var min = self.options.slider.min;
      // var max = self.options.slider.max;
      //var shape = self.instance[0].dataset.shape;

      var isInteger = false;
      if (self.instance !== null && self.instance.length > 0) {
        isInteger = self.instance[0].dataset.isinteger; // Must be all lowercase letters
      }

      // if (shape === "exponential") {
      //     value = Math.easeIn(value, min, max, 2.5);
      // }
      // if (isInteger === "false") {
      //     //value = value.toFixed(2);
      // } else {
      //     value = Math.round(value);
      // }
      if (isInteger !== "false") {
        value = Math.round(value);
      }

      if (self.options.slider.displayValue && self.options.slider.displayValuePercentage) {
        $('#' + self.displayValueId).css('left', self.valueTemplatePercentage(percentage) + '%').html(value);
      }

      if (self.options.slider.displayValue) {
        $('#' + self.displayValueId).html(value);
      }
    },

    valueTemplatePercentage: function (originalPercentage) {

      var width = {
        min: $('.' + self.options.slider.minClass).width(),
        range: $('.' + self.guid).width(),
        total: $('#' + self.rowId).width()
      };

      width.offsetPerc = width.min / width.total; // icon left in percent of total, i.e. 0.05
      width.rangePerc = width.range / width.total; // range in percent of total, i.e. 0.78

      var result = ((originalPercentage / 100 * width.rangePerc)) * 100;

      var resultWithOffset = result * (1 - width.offsetPerc) + (width.offsetPerc * 100);

      return resultWithOffset;

    },

    init: function (options) {
      var instance;
      var newGuid = Guid();
      self.displayValueId = 'display-value-' + newGuid;
      self.rowId = 'slider-row-' + newGuid;

      // not bound to DOM
      if (!options.element) {
        // instance = $('<input ' +
        //     'type="range" ' +
        //     'min="' + self.instanceAttr.min + '" ' +
        //     'max="' + self.instanceAttr.max + '" ' +
        //     'value="' + self.instanceAttr.value + '" ' +
        //     'id="' + newGuid + '" ' +
        //     'step="' + self.instanceAttr.step + '" ' +
        //     '>' +
        //     '');
        instance = $('<input ' +
          'type="range" ' +
          'min="' + 0 + '" ' +
          'max="' + 1 + '" ' +
          'value="' + 1 + '" ' +
          'id="' + newGuid + '" ' +
          'step="' + 1 + '" ' +
          '>' +
          '');
      }

      // bound to DOM
      else {
        instance = $(options.element);
      }

      self.instance = instance;
      self.instanceAttr = {
        id: instance.attr('id'),
        min: parseFloat(instance.attr('min')),
        max: parseFloat(instance.attr('max')),
        value: parseFloat(instance.attr('value')),
        classes: instance.attr('class'),
        step: parseFloat(instance.attr('step')),
      };

      // generate guid
      self.guid = newGuid;

      // set default options based on input range
      self.options = options;
      self.options.replaceDOM = self.options.replaceDOM === undefined ? true : self.options.replaceDOM;
      self.options.slider.min = self.options.slider.min === undefined ? self.instanceAttr.min : self.options.slider.min;
      self.options.slider.max = self.options.slider.max === undefined ? self.instanceAttr.max : self.options.slider.max;
      self.options.slider.value = self.options.slider.value === undefined ? self.instanceAttr.value : self.options.slider.value;
      self.options.slider.classes = self.options.slider.classes === undefined ? self.instanceAttr.classes : self.options.slider.classes;
      self.options.slider.displayValue = self.options.slider.displayValue === undefined ? false : self.options.slider.displayValue;
      self.options.slider.step = self.options.slider.step === undefined ? self.instanceAttr.step : self.options.slider.step;

      self.prerender();
      if (self.options.replaceDOM) {
        self.replaceDOM();
      }
      return self;
    },

    prerender: function () {
      // get template
      var $templateWrap = $(self.template());
      var $input = $($templateWrap.find('input')[0]);

      // CHANGE - bind to external callback
      $input.change(self.onExternalChange);

      // INPUT -> CHANGE. bind to local func
      $input.on('input', function (e) {
        var self = $(this);
        e.preventDefault();
        self.trigger('change');
      });

      // set default value
      $input.val(self.options.slider.value);

      // save rendered template to slider instance
      self.renderedTemplate = $templateWrap.hide();

    },

    replaceDOM: function () {
      // wrap input element
      $(self.instance).after(self.renderedTemplate.fadeIn());

      // set initial background gradient
      var percent = self.getPercentByValue(self.options.slider.value);
      self.setPercentStyle(percent);

      // update knot value and percentage
      self.updateValueTemplate(self.options.slider.value, percent);


      $(self.instance).remove();
    },

    onExternalChange: function (e) {
      // set value
      var val = $(e.target).val();
      if (self.options.slider.onChanged) {
        self.options.slider.onChanged(val);
      }

      // set background percentage
      var percentage = self.getPercentByValue(val);
      self.setPercentStyle(percentage);

      // update knot value and percentage
      self.updateValueTemplate(val, percentage);
    },

    getPercentByValue: function (val) {
      var percentage = Helper.getPercentByMinMaxValue(self.options.slider.min, self.options.slider.max, val);
      return percentage;
    },

    setPercentStyle: function (val) {
      val = parseInt(val);
      if (self.options.slider.isTrondelagKraft) {
      }
      else {
        var bgStyle = Gradient.getStyle('#e8e9e9', '#FFFFFF', val, 3);
        $('.' + self.guid).attr('style', bgStyle);
      }
    },

    getValue: function () {
      return $('.' + self.guid).val();
    },

    destroy: function () {
      $('.' + self.options.guid).parent().parent().remove();
    },

    isTouch: function () {
      return $('html').hasClass('touch');
    }

  };

  return {
    init: self.init,
    replaceDOM: self.replaceDOM,
    guid: self.guid
  };
};

module.exports = Slider;
